<template>
   <div class="overall">
      <!-- 上半部分 -->
      <el-card class="chart-card">
         <!-- 上半部分右边内容 -->
         <div class="plane">
            <!-- 右边头部 -->
            <div class="ritherd">
               <el-col :span="8">
                  <div class="impose">
                     <el-card class="a" shadow="hover">
                        <div class="amount">
                           <div class="union">
                              <el-icon size="35px" color="#ffffff">
                                 <ShoppingCart />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count">{{ consumeIntegral }}</div>
                              <div class="crew">消费积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
               <el-col :span="8">
                  <div class="impose1">
                     <el-card shadow="hover">
                        <div class="amount">
                           <div class="union1">
                              <el-icon size="35px" color="#ffffff">
                                 <Coin />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count1">{{ goodsIntegral }}</div>
                              <div class="crew">商品积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
               <el-col :span="8">
                  <div class="impose1">
                     <el-card shadow="hover">
                        <div class="amount">
                           <div class="union2">
                              <el-icon size="35px" color="#ffffff">
                                 <Headset />
                              </el-icon>
                           </div>
                           <div class="magnitude">
                              <div class="count2">{{ serviceIntegral }}</div>
                              <div class="crew">服务费积分</div>
                           </div>
                        </div>
                     </el-card>
                  </div>
               </el-col>
            </div>

            <!-- 表格内容 -->
            <!-- 搜索框 -->
            <div>
               <div class="grabble">
                  <div style="width: 100%;display: flex;">
                     <!-- 姓名 -->
                     <el-input class="hunt" v-model="designation" placeholder="请输入会员姓名" size="large" clearable />
                     <!-- 编码 -->
                     <el-input class="hunt" v-model="serial" placeholder="请输入会员编码" size="large" clearable />
                     <!-- 会员组织名称搜索 -->
                     <el-select class="hunt" v-model="ability" placeholder="请选择组织名称" size="large" @click="multiple"
                        clearable @focus="closeIt" ref="selectIt" @clear="clearHcode" />
                     <!-- 订单号 -->
                     <el-input class="hunt" v-model="orderCode" placeholder="请输入订单号" size="large" clearable />
                     <!-- 订单状态 -->
                     <el-select v-model="orderStatus" placeholder="请选择订单状态" clearable size="large" class="hunt">
                        <el-option label="正常订单" value="3" style="width:100%; text-align-last: center;"></el-option>
                        <el-option label="退款订单" value="1" style="width:100%; text-align-last: center;"></el-option>
                     </el-select>
                     <!-- 派发时间 -->
                     <el-date-picker style="width: 20%;" v-model="value1" type="daterange" range-separator="至"
                        start-placeholder="开始时间" end-placeholder="结束时间" size="large" @change="immediUpdate" />
                     <!-- 搜索 -->
                     <el-button style="margin-left: 10px" type="primary" size="large" @click="searchBtn"
                        :loading='formative'><el-icon class="cancelgu">
                           <Search />
                        </el-icon>搜索</el-button>
                  </div>
               </div>
               <div class="statistics">
                  统计：{{ count }}条
               </div>
            </div>
            <div class="export-btn">
               <el-dropdown loading split-button type="primary" @command="handleCommand" :hide-on-click="around">
                  导出
                  <div class="loading" v-show="wrought"></div>
                  <!-- 导出 -->
                  <template #dropdown>
                     <el-dropdown-menu style="width: 117px;">
                        <el-dropdown-item command="orderDetail">订单明细</el-dropdown-item>
                        <el-dropdown-item command="goodsDetail">商品明细</el-dropdown-item>
                     </el-dropdown-menu>
                  </template>
               </el-dropdown>
            </div>
            <!-- 表格 -->
            <el-table :data="filterTableData" style="width: 100%" row-key="unionId" border
               :header-cell-style="{ background: '#F5F7FA' }" :key="1" v-loading="tableLoading">
               <!-- 会员姓名 -->
               <el-table-column fixed align="center" label="会员姓名" prop="name" :show-overflow-tooltip='true'
                  width="150" />
               <!-- 会员编号 -->
               <el-table-column label="会员编码" prop="username" align="center" :show-overflow-tooltip='true' width="150">
               </el-table-column>
               <!-- 所属工会 -->
               <el-table-column label="所属工会" prop="unionName" align="center" :show-overflow-tooltip='true' width="150">
               </el-table-column>
               <!-- 订单号 -->
               <el-table-column label="订单号" prop="sqtBizOrderId" align="center" :show-overflow-tooltip='true'
                  width="150">
               </el-table-column>
               <!-- 支付积分 -->
               <el-table-column label="支付积分" prop="payIntegral" align="center" :show-overflow-tooltip='true'
                  width="150">
               </el-table-column>
               <!-- 商品积分 -->
               <el-table-column label="商品积分" prop="goodsIntegral" align="center" :show-overflow-tooltip='true'
                  width="150">
               </el-table-column>
               <!-- 服务积分 -->
               <el-table-column label="服务费积分" prop="serviceIntegral" align="center" :show-overflow-tooltip='true'
                  width="150">
               </el-table-column>
               <!-- 订单状态 -->
               <el-table-column label="订单状态" align="center" width="150">
                  <template #default="scope">
                     <div v-if="scope.row.orderState == 1">部分退款</div>
                     <div v-if="scope.row.orderState == 3">正常订单</div>
                  </template>
               </el-table-column>
               <!-- 支付时间 -->
               <el-table-column label="支付时间" align="center" :show-overflow-tooltip='true' width="150">
                  <template #default="scope">
                     {{ formatPayTime(scope.row.payTime) }}
                  </template>
               </el-table-column>
               <!-- 商品同步状态 -->
               <el-table-column label="商品同步状态" align="center" width="150">
                  <template #default="scope">
                     {{ scope.row.isSynchronousOrder == 0 ? '未同步' : '已同步' }}
                  </template>
               </el-table-column>
               <el-table-column fixed="right" label="操作" align="center" :width="250" :show-overflow-tooltip='true'>
                  <template #default="scope">
                     <el-button type="primary" link :icon="Search"
                        @click="goodsDetail(scope.row.sqtBizOrderId)">商品查看</el-button>
                     <el-button type="danger" link :icon="Search"
                        @click="refundDetail(scope.row.sqtBizOrderId)">退款查看</el-button>

                  </template>
               </el-table-column>
            </el-table>
            <!-- 表格分页 -->
            <!-- 一页时候隐藏 hide-on-single-page="true" -->
            <el-pagination :hide-on-single-page="true" layout="prev, pager, next,sizes" :page-sizes="[6, 12, 18, 24]"
               :current-page="currentPage" :page-size="pagesize" :total="count" @size-change="handleSizeChange"
               @current-change="handleCurrentChange" />
         </div>

      </el-card>
      <!-- 所属组织弹窗 -->
      <div>
         <s3-layer v-model="visible" title="所属组织" :shadeClose="true" :area="['500px', '80vh']">
            <el-tree :data="texture" :props="defaultProps" accordion @node-click="dendrogram" />
         </s3-layer>
      </div>
      <!-- 导出提示弹窗 -->
      <div>
         <s3-layer v-model="anew" title="提示" :shadeClose="true" :area="['50px', '170px']">
            <div class="task">
               {{ quoth }}
            </div>
         </s3-layer>
      </div>

   </div>
</template>
<script>

import {
   useRouter,
   useRoute
} from "vue-router";
import {
   ref,
   onMounted,
} from 'vue';
import {
   ElMessage,
} from "element-plus";
import {
   getCoinDetail,
   getTableData,
   ZClist,
   FYdaoru,
   exportOrder,
   exportGoods,
   RWall
} from "../../utils/api";
import {
   useStore
} from "vuex";
import qs from 'qs';
import moments from 'moment'
// 导入 Search 图标
import { Search } from '@element-plus/icons-vue';
export default {
   name: 'xiaoxiangIndex',
   computed: {},
   setup () {
      const route = useRoute();
      // 批量派发
      const batch = ref(1)
      const duty = ref('批量派发')
      // 订单号
      let orderCode = ref('');
      // 订单状态
      let orderStatus = ref('');
      // 所属组织
      // 下载提示弹窗
      let anew = ref(false)
      let anew1 = ref(false)
      // 搜索加载
      const formative = ref(false)
      // 下拉点击加载
      const wrought = ref(false)
      // 弹窗提示内容
      let quoth = ref('');
      let quoth1 = ref('');
      // 数据总共条数
      let count = ref(null);
      // 定义时间格式化方法
      const formatPayTime = (timestamp) => {
         if (typeof timestamp === 'number') {
            return moments(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');
         }
         return timestamp;
      };
      // 导出的商品明细
      const detail = () => {
         let data = {
            // 消费开始时间
            startTime: a.value,
            // 消费结束时间
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code 
            unionCode: Hcode.value,
            // 订单状态 1 正常订单 2 部分退款订单
            orderState: orderStatus.value,
            // 订单号
            sqtBizOrderId: orderCode.value,
         }
         exportGoods(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }
      // 导出订单明细
      const payout = () => {
         let data = {
            // 消费开始时间
            startTime: a.value,
            // 消费结束时间
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code 
            unionCode: Hcode.value,
            // 订单状态 1 正常订单 2 部分退款订单
            orderState: orderStatus.value,
            // 订单号
            sqtBizOrderId: orderCode.value,
         }
         exportOrder(qs.stringify(data)).then((res) => {
            // console.log(res)
            if (res.data.code == 0) {
               anew.value = true
               quoth.value = '任务已提交，请在右侧查看进度'
            } else {
               anew.value = false
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }
      // 导出下拉按钮选择
      const handleCommand = (command) => {
         if (command == 'orderDetail') {
            payout()
         } else if (command == 'goodsDetail') {
            detail()
         }
      }
      //goodsDetail 商品详情
      const goodsDetail = (id) => {
         sessionStorage.setItem('goodsPageId', id);
         router.push({
            path: '/xiaoxiang/goodsDetail',
         });
      }
      //orderDetail 退款详情
      const refundDetail = (id) => {
         sessionStorage.setItem('refundPageId', id);
         router.push({
            path: '/xiaoxiang/refundDetail',
         })
      }
      // 下拉按钮动态收回
      const around = ref(false)

      // 加载中
      const acting = ref(false)
      // 表格加载状态
      const tableLoading = ref(true);

      // 按钮禁用
      const jinyong = ref(false)
      // 工会清空
      const clearHcode = () => {
         Hcode.value = '';
      };

      // 获取任务进度列表
      const schedule = ref([])
      // 获取任务进度状态
      const condition = ref(null)

      // 获取任务进度
      const missions = () => {
         RWall().then((res) => {
            if (res.data.code == 0) {
               schedule.value = res.data.data.systemTasksList
               condition.value = schedule.value[0].state
            } else {
               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
            }
         })
      }

      const pleased = ref()
      const timer = ref(0)
      const uploadHttpRequest = (data) => {
         const file = data.file;
         const formData = new FormData()
         formData.append('file', file)
         let datas = {
            excelFile: formData.get('file')
         }
         FYdaoru(qs.parse(datas)).then((res) => {
            if (res.data.code == 0) {
               // 按钮禁用
               jinyong.value = true
               // 按钮转圈
               acting.value = true
               duty.value = '请稍等'
               // 按钮颜色改变
               batch.value = 2
               anew1.value = true
               quoth1.value = '任务已提交，请在右侧查看进度'
               // 通过vuex传递状态 控制标签页是否能关闭
               tally.value.forEach((item) => {
                  // console.log(item)
                  if (present.value == item.path) {
                     item.allow = 2
                  }
               })

               timer.value = window.setInterval(function logname () {
                  // 获取任务接口
                  missions()
                  if (condition.value == 1) {
                     window.clearInterval(timer.value)
                     duty.value = '批量派发'
                     batch.value = 1
                     jinyong.value = false
                     acting.value = false
                     coinDetail()
                     list()

                     tally.value.forEach((item) => {
                        if (present.value == item.path) {
                           item.allow = 1
                        }
                     })
                  }
               }, 2000);
               pleased.value.clearFiles()
            } else if (res.data.code !== 0) {

               ElMessage({
                  message: res.data.msg,
                  type: "error",
                  center: true,
               });
               acting.value = false
            }
         })
      }


      let own = (row, colum, cellValue) => {
         if (typeof cellValue == 'number') {
            return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
         }
      }
      let reach = (row, colum, cellValue) => {
         if (typeof cellValue == 'number') {
            return moments(cellValue * 1000).format("YYYY-MM-DD HH:mm")
         }
      }
      // 列表数据循环
      const filterTableData = ref([]);

      // 分页获取积分的派发日志
      // 动态加载数据
      let branches = ref(6)
      // 分页
      let currentPage = ref(1)
      // 每页显示条数
      let pagesize = ref(6)
      let handleSizeChange = (size) => {
         currentPage.value = 1;
         pagesize.value = size;
         tableLoading.value = true;
         coinDetail();
         list();
      }

      let handleCurrentChange = (size) => {
         currentPage.value = size;
         tableLoading.value = true;
         coinDetail();
         list();
      }


      // 加载中
      let texts = ref('加载中')
      // 搜索按钮
      let searchBtn = () => {
         formative.value = true;
         tableLoading.value = true;
         currentPage.value = 1; // 重置当前页码为1
         coinDetail();
         list();
      }
      let a = ref('')
      let b = ref('')
      let c = ref('')
      let out = ref('')


      // 时间清空监听
      const immediUpdate = (e) => {
         if (e !== null) {
            a.value = new Date(e[0]).getTime() / 1000
            b.value = new Date(e[1]).getTime() / 1000
            c.value = moments(b.value * 1000).format().substring(0, 10)
            out.value = new Date(c.value + ' ' + '23:59:59').getTime() / 1000
         } else if (e == null) {
            a.value = ''
            b.value = ''
            c.value = ''
            out.value = ''
         }
      }

      // 获取code 
      let Hcode = ref('')
      // 搜索组织选择值
      let ability = ref('')
      // 弹窗树形选择
      let dendrogram = (node) => {
         ability.value = node.unionName
         Hcode.value = node.unionCode
      }
      const lang = () => {
         ZClist().then((res) => {
            texture.value = res.data.data.manageUnionList
         })
      }
      // 弹窗组织列表树形
      let texture = ref([])
      const defaultProps = {
         children: 'unionDtoList',
         label: 'unionName',
      }
      // 选择组织按钮
      let multiple = () => {
         visible.value = true
      }
      // 搜索组织弹窗
      let visible = ref(false);
      // 会员编码框
      let serial = ref('');

      // 姓名输入框值
      let designation = ref('');


      // 总派发积分
      const serviceIntegral = ref(0);
      // 在途积分
      const goodsIntegral = ref(0)
      // 已换购积分
      const consumeIntegral = ref(0)
      // 派发积分 在途积分 已换购积分 
      const coinDetail = () => {
         tableLoading.value = true;
         let data = {
            // 消费开始时间
            startTime: a.value,
            // 消费结束时间
            endTime: out.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code 
            unionCode: Hcode.value,
            // 订单状态 1 正常订单 2 部分退款订单
            orderState: orderStatus.value,
            // 订单号
            sqtBizOrderId: orderCode.value,
         }
         getCoinDetail(qs.stringify(data)).then((res) => {
            if (res.data.code == 0) {
               // 消费积分
               consumeIntegral.value = res.data.data.xiaoXiangIntegralStatisticalInfo.consumeIntegral;
               // 商品积分
               goodsIntegral.value = res.data.data.xiaoXiangIntegralStatisticalInfo.goodsIntegral;
               // 服务费积分
               serviceIntegral.value = res.data.data.xiaoXiangIntegralStatisticalInfo.serviceIntegral;
               acting.value = false;
            }

         }).catch((error) => {
            console.error('coinDetail 请求失败:', error);
            ElMessage({
               message: '获取积分详情失败',
               type: "error",
               center: true,
            });
         })
            .finally(() => {
               tableLoading.value = false; // 无论请求成功或失败，都隐藏加载状态
            });
      }
      // 分页获取表格数据
      const list = () => {
         tableLoading.value = true;
         let params = {
            // 一页多少条数据
            limit: pagesize.value,
            // 分页页码（第几页）
            page: currentPage.value,
            // 会员姓名
            name: designation.value,
            // 会员编码
            username: serial.value,
            // 工会code
            unionCode: Hcode.value,
            // 消费时间开始时间戳
            startTime: a.value,
            // 消费时间结束时间戳
            endTime: out.value,
            // 订单状态 3：正常订单 1：部分退款订单
            orderState: orderStatus.value,
            // 订单号
            sqtBizOrderId: orderCode.value,
         }
         getTableData(qs.stringify(params)).then((res) => {
            let data = res.data;
            if (data.code == 0) {
               //总条数
               count.value = data.data.xiaoXiangOrderPage.totalCount;
               // 表格数据
               filterTableData.value = data.data.xiaoXiangOrderPage.list;
               acting.value = false;
               tableLoading.value = false;
               formative.value = false;
            }
         })
            .catch((error) => {
               console.error('list 请求失败:', error);
               ElMessage({
                  message: '获取表格数据失败',
                  type: "error",
                  center: true,
               });
            })
            .finally(() => {
               tableLoading.value = false; // 无论请求成功或失败，都隐藏加载状态
            });

      }

      let selectIt = ref()
      // 下拉隐藏
      let closeIt = () => {
         selectIt.value.blur();
      }


      // 时间选择
      let value1 = ref('')
      const whirl = ref(false)





      // vuex获取状态
      const store = useStore();
      // 获取当前页path
      const present = ref('')
      // 通过vuex获取标签栏
      const tally = ref([])

      onMounted(() => {
         // 获取标签栏数组
         tally.value = store.state.tagsList
         // 获取当前页path
         present.value = route.path

         coinDetail(); //积分明细
         lang(); //获取组织列表正常工会
         list()
      })

      // 待办内容点击事件
      const router = useRouter()
      const backlog = (row) => {
         router.push({
            path: '/homes'
         })
      }
      // 待办内容
      const tableData = [];
      return {
         goodsDetail, // 商品详情
         refundDetail, // 退款详情
         batch,
         duty,
         orderStatus,
         orderCode,
         own,
         quoth1,
         quoth,
         anew,
         // 过期按钮
         searchBtn,
         // 搜索加载
         formative,
         // 导出下拉按钮选择
         wrought,
         handleCommand,
         around,
         pleased,
         acting,
         tableLoading,
         uploadHttpRequest,
         // error,
         jinyong,
         clearHcode,
         formatPayTime,
         immediUpdate,
         reach,
         filterTableData,
         // 消费积分
         consumeIntegral,
         // 在途积分
         goodsIntegral,
         // 派发总积分
         serviceIntegral,
         // 失败积分跳转
         // 分页
         count,
         branches,
         handleCurrentChange,
         handleSizeChange,
         currentPage,
         pagesize,
         // 组织弹窗
         ability,
         texture,
         visible,
         dendrogram,
         defaultProps,
         multiple,
         // 会员编码框
         serial,
         // 会员姓名
         designation,
         // 时间选择
         whirl,
         value1,
         tableData,
         backlog,
         texts,
         closeIt,
         selectIt,
         // 导出 Search 图标
         Search
      };
   },

};
</script>
<style lang='scss' scoped>
// 2025年3月25日15:21:06
.export-btn {
   width: 100%;
   display: flex;
   justify-content: right;
   margin-bottom: 15px;
}

// ----------





.bulur {
   color: #409eff;
   border: none;
   --el-button-bg-color: none;
   --el-button-hover-bg-color: none;
   --el-button-hover-text-color: none;
}

.bulur:hover {
   color: #a0cfff;
}

.task {
   font-size: 14px;
}


.loading {
   width: 8px;
   height: 8px;
   border: 2px solid #C9E3FF;
   border-top-color: transparent;
   border-radius: 100%;
   animation: circle infinite 0.75s linear;
   margin-right: 5px;
}

// 转转转动画
@keyframes circle {
   0% {
      transform: rotate(0);
   }

   100% {
      transform: rotate(360deg);
   }
}

::v-deep .el-col-8 {
   max-width: 25.5% !important;
}



::v-deep .el-table__header {
   margin: 0 !important;
}

/* 调整表格边距 */
::v-deep .el-table .el-table__cell {
   padding-top: 15px;
   padding-bottom: 15px;
}

.el-pagination {
   margin-top: 10px;
}

.distribute {
   display: flex;
   align-items: center;
}

.hunt {
   width: 13%;
   margin-right: 10px;
}

.grabble {
   width: 100%;
   margin-top: 20px;
   margin-bottom: 15px;
   display: flex;
   justify-content: space-between;
}

.el-card.is-always-shadow {
   width: 95%;
   padding: 0 20px 20px 20px;
}

::v-deep .chart-card::-webkit-scrollbar {
   display: none
}

.chart-card {
   height: calc(100vh - 150px);
   overflow: auto;
   /* padding: 10px; */
}

.el-avatar {
   --el-avatar-bg-color: #F5F5F5;
}

.statistics {
   font-size: 14px;
   color: #909399;
}

// 右半部分下边内容
.el-table--enable-row-transition .el-table__body td.el-table__cell {
   cursor: pointer;
}

.el-checkbox__label {
   padding-left: 25px;
}

::v-deep .el-table__body {
   width: 96% !important;
   margin: auto;
   // margin-top: 10px;
   // margin-bottom: 20px;
}

.el-table__footer {
   width: 96% !important;
   margin: auto;
   // margin-top: 10px;
   // margin-bottom: 20px;
}



.impose1 {
   margin-left: 10px;
}

.crew {
   font-size: 14px;
   color: #999;
}

.count1 {
   font-size: 25px;
   font-weight: bold;
   color: rgb(100, 213, 114);
}

.count2 {
   font-size: 25px;
   font-weight: bold;
   color: #E6A23C;
}



.count {
   font-size: 25px;
   font-weight: bold;
   color: rgb(45, 140, 240);
}

.magnitude {
   width: 71%;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
}

/* .el-card {
		width: 99%;
	} */

.amount {
   display: flex;
   flex-direction: row;
}

.ritherd {
   width: 98%;
   display: flex;
   flex-direction: row;
   margin-bottom: 20px;
   margin-top: 20px;
}

.union1 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: rgb(100, 213, 114);
}

.union2 {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: #E6A23C;
}



.union {
   display: flex;
   justify-content: center;
   align-items: center;
   width: 100px;
   height: 100px;
   background-color: rgb(45, 140, 240);
}

::v-deep .el-card__body {
   padding: 0;
}

.plane {
   width: 100%;
}

.overall {
   margin-top: 10px;
   display: flex;
   justify-content: center;
   align-items: center;
   margin-bottom: 10px;
}

::v-deep .el-dialog__body {
   padding: 10px 10px 10px 10px !important;
   height: 215px !important;
   overflow: auto !important;
}

::v-deep .el-dialog__body::-webkit-scrollbar {
   display: none;
}
</style>
